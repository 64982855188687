@import "./node_modules/bootstrap/scss/functions"; 
@import './variables.scss';
@import "./node_modules/bootstrap/scss/variables"; 

$parallax-perspective: 8px;

@mixin transform($transforms) {
	-webkit-transform: $transforms;
	-moz-transform: $transforms;
	-ms-transform: $transforms;
	transform: $transforms;
}

@mixin transition($transition) {
  -webkit-transition: -webkit-$transition;
  -moz-transition: -moz-$transition;
  transition: $transition;
}

@function parallax-scale-factor($z-distance) {
  @return ($parallax-perspective - $z-distance) / $parallax-perspective;
}

@mixin parallax($z-distance) {
  transform: translateZ(#{$z-distance}px) scale(parallax-scale-factor($z-distance));
  // .col > h1, .col > h2, .col > h3, .col > h4, .col > h5, .col > h6, .col > p {
  //   transform:  scale(parallax-scale-factor($z-distance));
  // }
}

:lang(hu) {
  quotes: '\201E' '\201D' '\00BB' '\00AB';
}
:lang(en) {
  quotes: '\201C' '\201D' '\2018' '\2019';
}

q:before { content: open-quote; }
q:after { content: close-quote; }

header#hero {
  background-color: #000;
}

h1 {
  color: $white;
  text-transform: uppercase;
  font-size: 4rem;
}

section h2 {
  margin-bottom: 2rem;
  padding-top: 5rem;
}

.bg-dark2 {
  background-color: #000;
}

section, footer {
  margin-top: 2rem;
  h3 a {
    font-size: 1.4rem;
    display: block;
  }
  .card-body h4 {
    font-size: 1.2rem;
  }
}

section, footer, #content {
  background-color: #000;
  position: relative;
  z-index: 100;
}
footer {
  border-top: solid 1px #3a3a3a;
  padding: 0.7rem;
}

.container {
  background-color: #000;
}

.fullscreen {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  background-color: transparent;
  height: 100vh;
  min-height: 25rem;
  width: 100%;
  max-width: 1920px;
  max-height: 1080px;
  overflow: hidden;
  transform: translateZ(0px);

  video {
    position: absolute;
    top: 50vh;
    left: 50vw;
    width: 100% !important;
    height: auto !important;
    z-index: 0;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.2;
    z-index: 1;
  }
}

.blockquote {
  position: relative;
  font-family: 'Sanchez', serif;
  font-style: italic;
  margin-left: 3rem;
  margin-right: 3rem;
  margin-bottom: 3rem;
  &:before {
      content: '\201E';
      position: absolute;
      bottom: -3rem;
      left: -3.5rem;
      color: $gray-700;
      font-size: 6em;
      z-index: -1;
   }
   &:after {
    content: '\201D';
    position: absolute;
    top: -3rem;
    right: -3.5rem;
    color: $gray-700;
    font-size: 6em;
    z-index: -1;
 }
 footer {
  font-family: 'Noto Sans', sans-serif;
  font-size: 0.6em;
  font-weight: 700;
  color: $gray-700;
  float: right;
  background-color: inherit;
  padding: 0px;
  border: 0;
  margin: 0;
  &:before {
    content: '\2015';  
  }
 }
}

.reference {
  margin-bottom: 1rem;
}

.fader {
  opacity: 0;
  transition: opacity 2s;
}

// By default, the top navigation is hidden
#top-nav {
  top: 0;
  justify-content: space-between;
  //opacity: 0.8;
  @include transition(top 0.7s ease-in-out);
  &.hidden {
    top: -1 * (($navbar-padding-y + $navbar-brand-padding-y)*2+3);
  }

  .collapse.navbar-collapse {
    flex-grow: 0;
  }
}

#hero {
  z-index: 0;
}

#rolunk.transformed {
  .page-title {
    // margin-left: 15px;
    transform-origin: top left;
    display: inline-block;
    h2 {
      display: inline-block;
    }
  }
  .page-content {
    transform-origin: bottom right;
  }
}

#page-title-canvas {
  position: sticky;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
}

#lottie-anim {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  canvas {
    position: absolute;
    top: 0;
    left: 0;
  }
}

// loader
.loader {
  color: #0701ed;
  font-size: 40px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}
@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
// end loader

@import "./node_modules/bootstrap/scss/bootstrap.scss";
@import "./node_modules/bootswatch/dist/slate/bootswatch";